@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.observaciones {
    width: 85%;
    margin-left: 8%;
    margin-top: 32px;

    .TextAreaBlock{
        .SpanGray{
            color: $persian-indigo;
        }
    }
}

.body {
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 14px;
    height: auto;
    margin-bottom: 32px;
}

.button__container{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 24px;
}

.headerBody {
    width: 100%;
    margin-bottom: 21px;
    padding: 0px;
    h3{
        margin: 0;
        color: $strong-purple;
        font-size: 21px;
    }
    
}

@include mobile {
    .observacionesContent {
        padding: 10px 20px;
        h3 {
            text-align: center;
        }
    }
}

.observaciones{
    .button__container{
        .white{
            --background: #F6F7FC;
            --color: #6429CD;
        }
    }
}

.observacionesContent {
    padding: 32px;
}

@media (max-width: 700px) {
    .observaciones {
        .button__container {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            width: 90%;
        }
        .button__container Button {
            margin-left: 5px;
            margin-right: 5px;
            width: 90%;
            font-size: .7rem;
            padding: 8px 20px;
        }
    }
}