@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.container {
    padding: 0px !important;
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 35px 20px !important;

}

.main__title {
    color: $strong-purple;
    font-size: 21px;
    font-weight: 700;
}


.title__h3 {
    word-wrap: none;
    color: $persian-indigo;
    font-size: 14px;
    font-weight: 600;
}

.files {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ul{
        flex-wrap: wrap;
        width: 100%;
        li{
            padding-bottom: 10px;
            padding-top: 10px;
            border-bottom: 1px solid #AB88E759;
            border-left: none;
            border-right: none;
            button{
                margin: 0;
            }
        }
    }

    @include tablet {
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
    }
}

.first__file {
    width: 31%;
    height: fit-content;

    @include tablet{
        width: 50%;
        padding: 0 10px;
    }

    @include tablet{
        margin: 0% 5% 0 5%;
        width: 100%;
        padding: 0;
    }
}

.second__file {
    width: 31%;

    @include tablet{
        width: 50%;
        padding: 0 10px;
    }

    @include tablet{
        margin: 0% 5%;
        width: 100%;
        padding: 0;
    }
}

.third__file {
    width: 31%;

    @include tablet{
        width: 50%;
        padding: 0 10px;
    }

    @include tablet{
        margin: 0% 5%;
        width: 100%;
        padding: 0;
    }
}