@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.concept {

    --pds-color-gray-400: #828285;
    .concept-header {
        display: flex;
        justify-content: space-between;
        color: $persian-indigo;
        margin: 20px 0px 0px 0px;
        h3 {
            margin: 0;
            color: $strong-purple;
            font-size: 21px;
            font-weight: 600;
        }
    }
    .head {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__description {
            font-size: 14px;
            font-weight: bold;
            color: $persian-indigo;
            margin: 0;
            &.disabled-textArea {
                color: #828285;
            }
        }

        &__main{
            display: flex;
            gap: 16px;
            align-items: center;
        }
        .Textblock {
            width: 25%;
            .TextField {
                div {
                    width: 100%;
                }
            }
        }
        pds-input {
            width: 25%;
            margin: 10px 0px;
        }
    }
    .cost-center {
        &-dropdowns{
            display: flex;
            flex: wrap;
            justify-content: space-between;
            padding: 34px 0px 20px 0px;
        }
        &-lastRow{
            display: flex;
            justify-content: space-between;
        }
        .Textblock {
            width: 22%;
            display: flex;
            align-items: flex-end;
            .input-text input {
                border-bottom: 1px solid $backgroundGray;
                text-align: end;
                padding-right: 1.5rem;
            }
        }
        .select {
            width: 30%;
            .title--disable{
                color: #828285;
            }
        }
        pds-input {
            display: flex;
            width: 33%;
            --pds-color-gray-400 : #828285; 
        }
    }
    .add-cost-center__container{
        display: flex;
        position: relative;
        right: -34%;
        top: -43px;
        width: 65%;
        .button-addButton{
            margin: 0 !important;
        }
    }
    .total {
        display: flex;
        justify-content: flex-end;
        h3 {
            margin-left: 180px;
            font-size: 21px;
        }
    }
    button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        font-weight: 800;
    }
    button:hover {
        transition: all 0.6s ease;
    }
    .cost-center__delete {
        border: none;
        background-color: transparent;
        outline: none;
        margin: 0;
        padding: 0;
        width: auto;
        cursor: pointer;
        .Images {
            height: 24px;
            width: 24px;
            &:focus,
            &:hover {
                filter: invert(44%) sepia(27%) saturate(4267%) hue-rotate(1deg) brightness(102%) contrast(104%);
            }
        }
    }
    .concept__delete {
        border: none;
        background-color: transparent;
        font-family: 'Poppins', sans-serif;
        border: $strong-purple 1px solid;
        border-radius: 50px;
        padding: 6px 20px;
        outline: none;
        display: flex;
        align-items: center;
        color: $strong-purple;
        margin: 0px;
        width: auto;
        font-weight: 600;
        font-size: 14px;
        &:hover {
            background-color: $strong-purple;
            color: $ghost-white;
            box-shadow: 0px 0px 20px 0px #BDBDFF33 inset, 0px 0px 15px 0px #6429CD;
            cursor: pointer;
        }
    }

    &-fields-date{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.head__descripcion:disabled {
    color: $secondaryFontGray;
    background-color: #9D9DA114;
    border-color: #9D9DA14D;
    opacity: 0.6;
    overflow-y: auto;
    height: fit-content;
    // border-bottom: none;
}

.head__descripcion {

    resize: none;
}

.head__orde_compra input {
    text-transform: uppercase;
}

@include tablet {
  .concept {
      padding: 5%;
      .concept-header {
          background-color: transparent;
          padding: 0px;
          margin-bottom: 25px;
      }
      .head {
          align-items: center;
          padding: 0px;
          flex-direction: column;
          .head__descripcion {
              width: 100%;
          }
          .head__orde_compra {
              margin-top: 5%;
              width: 98% !important;
              margin-left: -10px;
          }
      }
      .cost-center {
          display: flex;
          flex-wrap: wrap;
          &-dropdowns {
            flex-direction: column;
            width: 100%;
        }
        pds-input{
            width: 100%;
        }

        &-lastRow{
            width: 100%;
        }

      }
      .cost-center {
          .select {
              width: 100%;
              margin-top: 15px;
              margin-bottom: 2%;
          }
          .said {
              margin-top: 15px;
              margin-bottom: 10px;
          }
      }

      .add-cost-center__container {
        top: 0;
      }
      
      .total {
          justify-content: flex-start !important;
          align-items: center;
          height: 35px;
          border-radius: 15px;
          h3 {
              font-size: .8rem;
              margin: 0px;
              padding: 3px;
          }
      }
      .button {
          margin-top: 5%;
      }

      .TextArea {
        width: 100%;
      }
  }
}