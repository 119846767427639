@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

@mixin shrinkLabel {
    top: -25px;
    font-size: 14px;
}

.select {
    width: 100%;
    height: 40px;
    position: relative;
    border: 1px solid #6429CD;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6429CD14;
    z-index: $z-top;

    .title {
        color: $persian-indigo;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: -25px;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        width: 88%;
        z-index: 8;

        &.shrink {
            @include shrinkLabel();
        }
    }

    .select__dropdown {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .select__placeholder {
            flex-grow: 1;
            font-size: $fontSizeLarge;
            padding-left: 15px;
            color: #6429CD66;
        }

        &.open .select__list {
            display: block;
        }
    }

    .select__list {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
        background: $purple-background;
        border: $strong-purple 1px solid;
        box-shadow: 0px 11px 24px 10px rgba(100, 41, 205, 0.24);
        border-radius: 15px;
        z-index: 10;
        list-style: none;
        margin: 0;
        padding: 0;

        &::-webkit-scrollbar{
            width: 6px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $strong-purple;
            border-radius: 20px;
        }

        .select__option {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: $strong-purple;
                box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.6) inset;
            }
        }
    }

    .Images {
        width: 2.5rem;
        position: relative;
        width: 32px;
        height: 32px;
        right: 2px;
        transition: transform 0.3s ease;

        &.rotate {
            transform: rotate(180deg);
            }

        @include tablet {
            width: 2rem;
        }

        @include mobile {
            width: 2.5rem;
        }
    }

    i {
        width: 0.5rem;
        height: 0.5rem;
        display: block;
        border-left: 2px solid $secondaryFontGray;
        border-bottom: 2px solid $secondaryFontGray;
        transform: rotate(-45deg);
        transition: all 0.3s ease;
        position: absolute;
        right: 5px;
        z-index: 0;

        &.rotate {
            transform: rotate(135deg);
        }
    }

    &:hover {
        border-bottom: 1px solid $primary-purple;

        .title {
            @include shrinkLabel();
            transition: 200ms ease top;
        }
    }
}

.dropdown-disabled{
    border: 1px solid $cornflower-blue;
    background-color: #9D9DA114;
    &:hover {
        border: 1px solid $cornflower-blue;
    }
    .select__placeholder{
        color: #828285 !important;
    }
    .title{
        color: #828285 !important;
    }
}

.select.select-disabled {
    i {
        width: 0.5rem;
        height: 0.5rem;
        display: none;
        border-left: 2px solid $secondaryFontGray;
        border-bottom: 2px solid $secondaryFontGray;
        transform: none;
        transition: none;
        position: absolute;
        right: 5px;
        z-index: 0;

        &.rotate {
            transform: none;
        }
    }

    &:hover {
        .title {
            @include shrinkLabel();
            transition: 200ms ease top;
        }
    }

    .select__list {
        cursor: default;

        .select__option {
            cursor: default;
        }
    }
}

.select__list:required {
    padding-left: 15px;
    color: $purple-heart-o6;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 700px) {
    .select {
        width: 90%;
    }
}