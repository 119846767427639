@import "../../../styles/variables.scss";

.invoice-toolkit__search{
  margin-right: 7%;
}

.invoice-table {
  .table-container{
    margin-top: 76px;
  }
}

.o-footer__table {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: flex-end;
}

.o-footer__table nav {
  text-align: right;
}

.sequence-item {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  font-size: $fontSizeLarge;
  font-weight: 600;
  color: $persian-indigo;

  pds-checkbox {
    --font: 600 14px;
  }
}

.sequence-item.reverse {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sequence-item * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.sequence-prefix {
  white-space: nowrap;
}

.sequence-value label input {
  width: 95%;
  text-align: end;
  background-color: transparent;
  border-color: $primary-purple;
}

.trm-item {
  border-top: 1px solid $primary-purple;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding-top: 1.5rem;
  font-size: $fontSizeLarge;
  font-weight: 600;
  color: $persian-indigo
}

.trm-item * {
  background-color: transparent;
  margin-left: 0.5rem;
  flex-grow: 2;
  margin-right: 0.5rem;
}

.trm-item input {
  width: 95%;
  text-align: end;
  border-color: $primary-purple;
}

@media (max-width: 768px) {

  .sequence-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .sequence-item * {
    margin-top: 0.5rem;
  }

  .trm-item {
    border-top: 1px solid $primary-purple;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-top: 1.5rem;
  }

  .o-footer__table {
    flex-direction: column-reverse !important;
  }
}
