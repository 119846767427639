@import "../../../styles/variables.scss";

.brands_fields {

  background: $softPurple-background 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px $shadowWhite;
  border-radius: 10px;
  opacity: 1;
  width: auto;
  height: auto;
  padding: 32px;
  margin: 20px 0 30px;

  h3{

    color: $strong-purple;
    font-size: 21px;
    margin: 0;
  }

}

.associated_brands {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  .main__pill__button{
    padding: 10px
  }
}

.button_create_brands {
  padding: 1%;
  display: flex;
  justify-content: flex-end;
}
