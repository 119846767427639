@import "../../../styles/variables.scss";

.home {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 90%;

    .home-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;

        color: $white;
        border-radius: 24px;
        border: 1px solid #6429CD;
        background-color: #6429CD0D;
        box-shadow: 0px 4px 80px 0px #6429CD40 inset;

        margin-top: 10%;
        width: 85%;

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 64px;

            p {
                text-align: center;
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
            }

            h3 {
                margin: 0;
                font-size: 22px;
                font-weight: 700;
                line-height: 26.4px;
            }

        }


        .access-links {
            display: flex;
            justify-content: center;
            justify-self: center;
            flex-wrap: wrap;

            gap: 51px;
            margin: 32px 100px 64px 100px;
        }
    }

    &-message {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        border-radius: 16px;
        border: 1px solid #6429CD;
        background-color: #6429CD0D;
        box-shadow: 0px 4px 80px 0px #6429CD40 inset;

        margin-top: auto;
        margin-bottom: 0px;
        padding: 16px 15%;
        width: 85%;
        
        margin-right: 0%;

        p,
        a {
            text-align: center;
            display: inline;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
        }

        a {
            color: #AB88E7;
            font-weight: 600;
        }
    }
}