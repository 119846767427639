@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.a-inputSearch {
  height: 42px;
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 2.4rem;
  font-size: $fontSizeLarge;
  background-color: $softPurple-background ;
  border: solid 1px $grayBorder;
  color: $gun-powder;
  box-shadow: 0px 2px 8px 0px rgba(189, 189, 255, 0.36);
  width: 100%;
  border-radius: 100px;
  font-family: 'Poppins', sans-serif;
  @include tablet {
    width: 100%;
    margin: auto;
  }
}

input.a-inputSearch::placeholder {
  color: $gun-powder;
  font-size: $fontSizeLarge;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  width: 100%;
}

.a-inputSearch:focus {
  box-shadow: 0 0 2pt 1pt $primary-purple;
  outline: none;
}

.searchIconContainer {
  position: absolute;
  top: 8px;
  left: 4px;
}

.searchIcon {
  @include tablet {
    margin-right: 0px;
  }

  @include mobile {
    margin-right: 6px;
  }
}

.searchContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 300px;
  background: $softPurple-background;
  border-radius: 100px;
}

@media (max-width: 766px) {
  .a-inputSearch {
    padding: 1rem 0rem 1rem 3rem !important;
  }
}
