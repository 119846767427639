@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.SpanOrange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondaryFontGray;
  background-color: #6429CD14;
  border: $strong-purple 1px solid;
  border-radius: 50px;
  width: 95%;
  height: 40px;
  padding-left: 15px;

  :first-child {
    height: 32px;
    width: 32px;
    padding-right: 4px;
  }
}

.SpanGray {
  color: $persian-indigo;
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
}

.SpanGray.gray {
  color: $secondaryFontGray;
  font-weight: unset;
}

.Date {
  color: #8e8e8e;
  font-family: inherit;
  font-size: 14px;
}

.tooltip {
  width: 15%;
  height: auto;
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  color: black;
  font-size: 12px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  bottom: 100%;
  left: 50%;
  margin-left: -160px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.input-text {
  color: $secondaryFontGray;

  @include mobile {
    width: 90%;
  }
}

.input-text input {
  border: 0px;
  border-bottom: solid $secondaryGray 1px;
  width: 100%;
  height: 30px;
  font-size: 16px;
  padding: 1px;
  margin-left: 1%;
}

.input-text__body {
  position: relative;
}

.input-text__input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid $secondaryGray;
  color: $persian-indigo;
  transition: all 0.3s;
  padding-left: 20px;
  &:not(input:disabled):hover {
    border-bottom: 1px solid $primary-purple;
  }
  &--login {
    background-color: $secondaryGray;
    border-radius: 25px;
    padding-left: 20px !important;
    &:hover {
      border: 1px solid $primary-purple;
    }
  }
}

.input-text__placeholder {
  color: $purple-heart-o6;
  font-size: $fontSizeLarge;
  margin-left: 20px;
  margin-top: -30px;
  overflow: hidden;
  padding-bottom: 5px;
  position: absolute;
  text-overflow: ellipsis;
  transform-origin: top left;
  transition: all ease 200ms;
  white-space: nowrap;
  width: 97.1%;
  cursor: text;
  &--login {
    margin-left: 20px;
  }
  @include tablet {
    font-size: 15px;
  }

  @include mobile {
    font-size: 16px;
  }
}

.input-text__input:not(:placeholder-shown) + .input-text__placeholder {
  display: none;
  color: $FontGray;
  border: none;
}

.input-text__placeholder--label-trm {
  width: 163px;
}

.input-text__input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 1px solid $primary-purple;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"] {
  width: auto;
  height: 40px;
  border: 0;
  border: $strong-purple 1px solid;
  border-radius: 50px;
  padding-left: 15px;
  background-color: #6429CD14;

  @include mobile {
    width: 100%;
  }
}

input[type="date"]:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 1px solid $primary-purple;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  height: 32px;
  width: 32px;
  background: url("../../../images/ico-calendar.svg") no-repeat;
  background-size: 32px;
  background-position: bottom 2px right 4px;
}

input[type="search"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.error-message {
  font-size: 10px;
  color: $torch-red;
}

.error-indicator {
  color: $torch-red;
}

input:disabled {
  color: $primaryGray;
  background-color: white;
  opacity: 0.6;
  border-bottom: none;
}

@include mobile {
  .SpanGray {
    padding-bottom: 5px;
  }
}
