@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
.nav-link{
    padding: 5px 0;
    font-weight: 300;
    color: $primaryWhite;
    display: flex;
    align-items: center;
    span{
      @media (max-width:700px) {
        font-size: .8rem;
        margin:0;
        padding: 0;
      }
    }

    .nav-item {
      margin-left: 80px;
    }
}
@media (max-width:700px) {
  .nav-link{
    display: flex;
    align-items: center;
  }
}
.sidebarLink {
  padding: 20px 0;
}
.nav-link:hover{
    font-weight: 600;
    color: $primaryWhite;
}

.active .nav-link{
  color: white;
  font-weight: 600;
  filter: drop-shadow(3px 3px 8px rgba(255, 255, 255, 0.5)) drop-shadow(-3px -3px 8px rgba(255, 255, 255, 0.5));
}

@include tablet {
  .nav-link {
    margin-left: 80px;
    .nav-item {
      margin-left: 0;
    }
  }
}