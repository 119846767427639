@import "../../../styles/variables.scss";

div.novedad {
    background-color: $primaryWhite;
    position: relative;
    top: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    width: 95%;
    box-shadow: 0px 4px 6px 2px #0000000f, 6px 0px 6px 2px #0000000f, 0px -4px 6px 2px #0000000f;
    border-radius: 10px;
    opacity: 1;
    border-left: 5px;
    border-left-style: solid;
    border-color: $primary-purple;
}

div.primera{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.titulos {
    display: flex;
    flex-direction: column;
    color: $black;
}

.titulo_novedad {
    color: $primary-purple;
    margin-top: 20px;
    margin-bottom: 5px;
}

.nombre_de_la_novedad {
    margin-top: 0;
    margin-bottom: 5px;
}


.fecha {
    background-color: $secondaryGray;
    color: $black;
    height: fit-content;
    margin: 20px;
    padding: 5px;
    text-align: left;
    opacity: 1;

}

div.secundary{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $black;
}

.linea{
    border: 1px solid $darkGrayBorder;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.descripcion {
    width: 92%;
    text-align: left;
    font-size: $fontSizeNormal;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.imagen_novedad {
    width: 100%;
    display: flex;
    justify-content: center;
}

.imagen_novedad img {
    width: 90%;
    height: auto;
}