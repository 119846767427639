@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.input-file-block{
    color:$strong-purple;
    font-size: 12px;
    &.text-bold{
        font-weight: bold;
    }
}

.container-input-file-block {
    margin-top: 1rem;
}

.container-input-file-block.view {
    width: 100%;
    display: flex;
    align-self: center;
    padding-bottom: 20px;
}

@include mobile {
    .container-input-file-block {
        width: 95%;
        margin: 5% auto;
        text-align: center;
    }
}