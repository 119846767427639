@import "../../../styles/mixins.scss";

.TextSearchBlockNitCliente {
    display: flex;
    width: 100%;

    @include mobile {
        display: block;
    };

    @include tablet {
        width: 90%;
    };
}

.imageContent {
    width: 20px;
    height: 20px;
}

@include tablet {
    .TextSearchBlockNitCliente {
        display: block;
        width: 90%;
    }
}
