@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.t-wrapper {
  background-color: $purple-background;
  background-image: url('../../../images/background-texture.svg');
  color: white;
  display: flex;
  width: 100%;
  min-height: 100vh;
  margin: auto;
}

.t-wrapper__aside {
  z-index: 9;
  display: flex;
  width: 10%;
  max-width: 3rem;
  margin-right: 2.7%;
  height: 100%;
  position: fixed;
}


.t-wrapper__children {
  width: 100%;
  margin-left: 0px;
  margin-right: auto;
  padding: 1rem;
  position: relative;
  top: 160px;
}

.asideV2 .content-button button img {
  width: 80%;
}

.t-wrapper__children-not-scroll {
  overflow-y: visible ;
}


footer {
  display: flex;
  position: relative;
  top: 160px;
  left : 27%;
  width: 66%;
  img {
    z-index: 0;
    width: 100%;
    //display: none;
  }
}

@include tablet {
  .t-wrapper {
    flex-direction: column;
  }

  .t-wrapper__children {
    width: 90%;
    ;
    margin: 0 auto;
  }

  .t-wrapper__aside {
    z-index: 10;
    width: 0%;
    height: 0%;
  }

  .t-wrapper__children-blur {
    filter: blur(3px);
  }

  footer {
    left : 17%;
    }
}

@media screen and (min-width: 700px) {
  .t-wrapper__aside {
    width: 20%;
    max-width: 20%;
    position: relative;
  }
}