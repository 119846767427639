@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.disable-attach-block {
    pointer-events: none;
    color: $secondaryFontGray;
}

.attach-file {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__message-bold {
        justify-content: center;
        align-items: center;
        justify-self: center;
        align-self: center;
        font-weight: 400;
        color: $strong-purple;
        border-radius: 3px;
        padding: 8px 15px;
        font-size: 12px;

        @include mobile {
            font-size: 10px;
        }

        @include tablet {
            font-size: 10px;
        }
    }

    &__text-attach {
        color: $strong-purple;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 10px;

        height: 32px;

        border: 1px solid #6429CD;
        border-radius: 9999px;

        label{
            cursor: pointer;
        }

        @include mobile {
            font-size: 12px;
            padding: 8px;
        }

        @include tablet {
            font-size: 12px;
            padding: 8px;
        }

        
    }
}

@include mobile {
    .attach-file {
        text-align: center;
    }

    .attach-file__text-attach {
        font-size: 10px;
    }
}