.brand__form{
    margin-left: 8%;
    margin-bottom: 32px;
    width: 85%;
}  

.form__content__formBody{
    display: flex;
    flex-direction: row;
    justify-content:left;
    padding: 10px 24px 20px 24px;;
}

.form__content__formButton{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
}

.form__content__formBody__formChecked{
    display: flex;
    align-items: center;
}