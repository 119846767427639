@import "../../../styles/variables.scss";
.footer_aside {
    display: flex;
    align-items: center;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .footer_line {
        margin-top: 10px;
        width: 95%;

        .footer_copyright {
            height: 14px;
        }
    }
}

.initialName {
    background-color: $strong-purple;
    font-weight: 600;
    font-size: 14px;
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primaryWhite;
    text-transform: uppercase;
}

.content-name {
    margin-left: 10px;
    margin-top: 4px;
}

.rol {
    font-size: 11px;
    color: $primaryWhite;
}

.name {
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 600;
    color: $primaryWhite;
}

.content-ico {
    margin-left: 10px;
    margin-top: 4px;
    &:hover {
        cursor: pointer;
    }
}
