@import "../../../styles/variables.scss";


.brand-toolkit__search{
  position: relative;
  margin-right: 7%;
}

.brand-toolkit__button{
  position: relative;
  --background : white;
  --color : #6429CD;
  --padding-horizontal: 30px;
  --height: 40px;
}

.o-footer__table {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: flex-end;
}

.o-footer__table nav {
  text-align: right;
}

@media (max-width: 766px) {
  .sequence-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .sequence-item * {
    margin-top: 0.5rem;
  }

  .trm-item {
    border-top: 1px solid $primary-purple;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-top: 1.5rem;
  }

  .o-footer__table {
    flex-direction: column-reverse !important;
  }
}
