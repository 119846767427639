@import '../../../styles/mixins.scss';

.m-container__input{
    display: block;
    max-width: 996px;
    width: 300px;
    height: 42px;

    @include tablet {
        width: 80%;
        display: block;
    }

    @include mobile {
        width: 100%;
        display: block;
    }
}
