@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.form__content {
  .form__content__header {
    color: $strong-purple;
    font-size: 21px;
    padding: 32px 32px 24px 32px;

    h3 {
      margin: 0;
    }
  }

  .form__content__formBody {
    padding: 0 32px;

    .form__content__input {
      width: 33%;
      max-width: 100%;
      padding-right: 24px;
    }
  }

  .form__content__formBody__client {
    display: flex;
    position: relative;
    z-index: 1;

    .title__finder{
        color: $persian-indigo;
        font-weight: 700;
        font-size: $fontSizeLarge;
    }

    .dataClients__body__finder {
      width: 100%;
    }
  }
}

.brand-form__button{
  --background : white;
  --color : #6429CD;
  --height: 40px;
}

.bodyRequest {
  width: 96%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 32px;
}

@include mobile {
}

@include tablet {
    .form__content__formBody{
        flex-direction: column !important;

        .form__content__input{
            width: 100% !important;
        }
    }
    .form__content__formBody__client{
        flex-direction: column !important;
    }
    .form__content__formBody div{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .form__content__formBody__formChecked{
        margin: 0 auto !important;
    }
    .dataClients__body__finder{
        width: 90%;
    }
    .bodyRequest{   
        padding-top: 0;
    }  
    .brand-form__button {
      position: absolute;
      z-index: -1;
    }
}
