@import '../../../styles/variables.scss';

.message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  border: 1px $gray solid;
  border-radius: 2rem;
}

.message-container.success {
  border-color: $success;
}

.message-container.error {
  border-color: $primary-purple;
}

.message-icon {
  width: 48px;
  height: 48px;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.message-container span {
  font-size: $fontSizeMedium;
  padding: 0.5rem;
}

.message-container span a {
  font-weight: bold;
  color: $dark;
}
