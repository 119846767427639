@import "../../../styles/variables.scss";

.testButton{
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    background-color: $primaryWhite;
}

.testButton img {
    width: 16px;
    height: 16px;
}

.notifications {
    display: flex;
    justify-content: flex-end;
}

.notificationsAside {
    width: 373px;
    height: 100%;
    background-color: $ghost-white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 28px;
    right: 0;
    transform: scalex(0);
    transform-origin: right;
    transition: all 300ms ease;
    z-index: 2000;
}


.showNotifications + .notificationsAside {
    background-color: $backgroundGray;
    box-shadow: 0px 3px 6px $shadowGray;
    position: fixed;
    top: 28px;
    right: 0;
    transform: scale(1);
    transform-origin: right;
    transition: all 400ms ease-in-out;
    border: solid 1px #E8E8ED;
    border-top-left-radius: 25px;

    .notificationsContent {
        margin-left: 5%;
        overflow: auto;
        padding-bottom: 20px;
    }
}

.closeNotifications {
    border-top-left-radius: 25px;
    padding: 16px 0 0 18px;
    width: 100%;
    .iconButton {
        background: none;
        border: 0;
        color: $primaryWhite;
    }
    &:hover {
        cursor: pointer;
    }
}

.empty-notification {
    text-align: center;
    color: $black;
}

@media screen and (max-width: 700px) {
    .notificationsAside {
        width: 100%;
    }
    
}
