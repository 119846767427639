@import "../../../styles/variables.scss";

.enabled__text {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    text-align: left;
    color: #330072;
    margin: 0;
}

.group_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 76px;
    height: 40px;
}


.slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #D2D2D6;
    -webkit-transition: .4s;
    transition: .4s;

    &-true {
        position: absolute;

        height: 32px;
        width: 32px;
        left: 4px;
        top: 10px;

        font-size: 14px;
        font-weight: 700;
        line-height: 19.6px;
        text-align: center;
        color: white;
    }

    &-false {
        position: absolute;

        height: 32px;
        width: 32px;
        right: 4px;
        top: 10px;

        font-size: 14px;
        font-weight: 700;
        line-height: 19.6px;
        text-align: center;
        color: darkgray;
    }
}

.alo {
    background-color: aqua;
}

.slider:before {
    position: absolute;
    content: "";

    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;

    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider.round {
    border-radius: 100px;

    &::before {
        border-radius: 50%;
    }
}

input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider {
        background-color: $primary-purple;
    }

    &:focus+.slider {
        box-shadow: 0 0 1px $primary-purple;
    }

    &:checked+.slider:before {
        -webkit-transform: translateX(36px);
        -ms-transform: translateX(36px);
        transform: translateX(36px);
    }
}