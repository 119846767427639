/* Centra el input personalizado con el texto */
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.custom-radio-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100px;
}

.custom-radio-checkbox__text{
    color:$persian-indigo;
    font-size: .9em;
    padding: 5px;
}

/* Modificador para dar la imagen de radio */
.custom-radio-checkbox__show--radio {
    background-image: url('../../../images/radio-uncheck.png');
}

/* Oculta input original */
.custom-radio-checkbox > .custom-radio-checkbox__input {
    display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox > .custom-radio-checkbox__show {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: .5rem;
    background-size: cover;
}

/* Cambia el radio personalizado cuando es pulsado */
.custom-radio-checkbox > .custom-radio-checkbox__input:checked + .custom-radio-checkbox__show--radio {
    background-image: url('../../../images/radio-check.png');
}


