@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';


@mixin break {
  thead {
    display: table-header-group;
    background-color: #6429CD;
    color: white;
    height: 52px;
  }

  tr {
    display: table-row;
    height: 60px;
  }

  td {
    display: table-cell;
    padding: 5px 5px 5px 14px;
    text-align: left;
    overflow-wrap: break-word;
    max-width: 5rem;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &::before {
      content: none;
    }
  }
}

.table-container {
  width: 85%;
  margin-top: 32px;
  margin-left: 8%;
  border-radius: 14px;
  background-color: #F6F7FC;

  .table__body {
    tr {
      &:hover {
        background-color: #BDBDFF;
        background-size: 100% 100% !important;
      }
    }
  }

  &__table {
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
    border-radius: 14px;
    border-collapse: collapse;
  }

  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    height: auto;
    font-size: 11px;
    font-weight: 400;
    padding: 0.25rem 0.25rem 0.25rem 140px;

    color: #330072;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid #3b177a;
    }

    &:last-child {
      border-bottom: 1px solid #3b177a;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      top: 0;
      left: 0;
      width: 120px;
      min-height: 30px;
      height: 100%;

      color: white;
      font-size: 14px;
      background-color: #3b177a;
    }

    &:empty::after {
      content: "\00a0";
    }
  }

  th {
    padding-left: 1rem;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;

    &::before {
      background-image: url('../../../images/ico-arrow-updown.svg');
    }

    &:hover {
      background-color: #330072 !important;
      background-size: 100% 100% !important;
    }

    &.orderingColumn {
      /* Es "Nombre" */
      font-weight: 700;
      background-color: #6429CD;
      background-size: 100% 100% !important;
    }

    .tableHeaderOrder {
      display: flex;
      flex-direction: row;
      text-overflow: ellipsis;
      overflow: hidden;
      align-items: center;

      .tableHeaderOrderIcon {
        width: 16px;
        height: 16px;
        margin-right: 0.5rem;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &__table--break-lg {
    @media screen and (min-width: $large) {
      @include break;
    }
  }

  &__table--break-md {
    @media screen and (min-width: $medium) {
      @include break;
    }
  }
}

.m {
  &-tablerow__dark {
    background-color: $softPurple-background;
  }

  &-emptyTable {
    td {
      text-align: center;
      padding: 1rem;
    }
  }
}

@include tablet-large {
  .table-container {
    td {
      font-size: 9px;
    }
  }
}