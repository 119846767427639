@import "../../../styles/variables.scss";

.main__pill__container{
    display: inline-block;
    margin-top: 24px;
    margin-right: 16px;
}

.main__pill__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 4px 3px 10px;
    background-color: $backgroundPurple;
    border: none;
    color: $persian-indigo;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    cursor: default;
    border-radius: 32px;
    border: 1px solid $strong-purple;
}


.boxclose{
    margin-left: 15px;
    cursor:pointer;
    color: $primaryWhite;
    border-radius: 30px;
    background: $primary-purple;
    font-size: 20px;
    display: inline-block;
    line-height: 0px;
    padding: 8px; 
    position: relative;      
}

.boxclose:before {
    content: "";
    display: inline-block;
    width: 16px; 
    height: 16px; 
    background-image: url('../../../images/ico-close-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.boxclose:hover{
    transition: .3s all;
    box-shadow: 0px 0px 24px 10px rgba(100, 41, 205, 0.24);
}