@import '../../../styles/mixins.scss';

.usersTable{
  position: relative;
  z-index: 1;
  margin-top: 57px;
  .table-container{
    margin-top: 30px;
  }
}
.users-toolkit__search{
  margin-right: 7%;
}

@include tablet {
  .users-toolkit__search{
    margin-right: 0;
    margin-top: -5rem;
  }

  .usersTable{
    position: relative;
  }
  
}