@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";


.sidebarLink {
    width: 100%;
    display: flex;
    color: $primaryWhite;
    align-items: center;
    margin-right: auto;
    font-weight: 500;

    &:hover {
        background: radial-gradient(50% 82.32% at 50% 50%, #440E91 0%, #551CB0 84.5%);
    }

    &--active {
        background: radial-gradient(50% 82.32% at 50% 50%, #440E91 0%, #551CB0 84.5%);
    }

    span {
        font-size: $fontSizeLarge;
    }
}

.indicator {
    width: 5px;
    height: 32px;
    border-radius: 120px;
    background-color: transparent;
    box-sizing: border-box;
    box-shadow: transparent;

    &--active {
        background-color: #ffffff;
        box-shadow: 0px 0px 8px #fff;
    }
}

.label {
    font-weight: 300;
    font-size: 16px;

    &--active {
        font-weight: 600;
    }
}

.menu_circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1.5px solid #fff;
    margin-left: 24px;

    &:hover {
        border-color: #bdbdff;
        filter: drop-shadow(3px 3px 8px #fff) drop-shadow(-3px -3px 8px #fff);
    }

    &--active {
        border-color: #bdbdff;
        filter: drop-shadow(3px 3px 8px #fff) drop-shadow(-3px -3px 8px #fff);
    }
}

.menu_circle img {
    width: 14px;
    height: 16px;
    object-fit: fill;
}

.sidebarLink__iconOpenClose {
    margin-left: auto;
    margin-right: 5%;
}

.sidebarLink__icon {
    margin-left: 10px;
}

.subNav {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 500ms linear, max-height 500ms ease-in-out;
    margin-bottom: 0;
}

.subNav.subNavActive {
    opacity: 1;
    max-height: 1000px;
    margin-bottom: 24px;    
}
