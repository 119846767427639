@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.countries-toolkit__search{
    margin-right: 7%;
}

.edit__location3 {
    margin-left: 8%;
    margin-bottom: 32px;
    margin-top: 76px;
    width: 85%;
    background: $softPurple-background;

    .form__input {
        margin: 24px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }


    .form__button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        .manage-countries__button-clean{
            margin: 1px 5px;
            width: 184px;
            --background : transparent;
            --border: #6429CD solid 1px;
            --color : #6429CD;
        }
        .manage-countries__button-edit{
            width: 184px;
            --background : #6429CD;
            --color : #FFFFFF;
        }
    }
}

.information__title {
    margin: 0;
    margin-top: 32px;
    font-weight: 21px;
    font-weight: 700;
    color: #6429cd
}

pds-input {
    width: 100%;
    margin: 0;
}
