@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

$primary-purple: #6F2FD3;
$purple-background: #1F0D3F;
$softPurple-background: #E7DDF8;
$strong-purple: #6429CD;
$secondary-purple: #e4e3ff;
$primary-fucsia: #8F1BC3;
$purpleShadow: #e4e3ff1f;

$gradient: transparent linear-gradient(270deg, $primary-purple 0%, $primary-fucsia 100%) 0% 0% no-repeat padding-box;
$background-texture: '../images/background-texture.svg)';

$primaryGray: #444444;
$secondaryGray: #F8F8F8;
$lightGray:#C1C1C1;
$gray:#C9C9C9;
$grayBorder: #e5e5e5;
$darkGrayBorder: #707070;

$FontGray:#4D4D4D;
$secondaryFontGray: #8E8E8E;

$disabledGray: #e6e2e2;
$loaderGray: #f3f3f3;
$loaderBackground: #0A090C4D;
$sliderDisabled: #ccc;
$backgroundGray:#EFEFEF;
$backgroundPurple: #6429CD14;

$primaryWhite:#FFFFFF;
$shadowWhite:#0000000F;
$shadowGray:#00000029;
$boxShadow: #00000015;
$darkShadow: #0000000d;

$dark:#333333;

$red-dark: #ff2600;
$success: #a5dc86;

/* Breakpoints */

$small: 440px;

/*-------- Mobile devices*/

$s-medium: 570px;

/*-------- Tablet devices*/

$medium: 768px;
$large: 960px;
$x-large: 1060px;

/*-------- Desktop devices*/

$xx-large: 1250px;
$xxx-large: 1440px;
$high-quality: 1700px;

/*------- Font sizes */
$fontSizeExtraLarge: 18px;
$fontSizeLarge: 14px;
$fontSizeNormal: 12px;
$fontSizeMedium: 10px;
$fontSizeSmall: 8px;

@function size($target, $context: $base-size) {
    @return calc($target / $context) * 1rem;
  }
  
  /* Breakpoints */
  $s-small: 320px;
  $small: 440px;
  /*-------- Mobile devices*/
  $s-medium: 570px;
  /*-------- Tablet devices*/
  $medium: 768px;
  $large: 960px;
  $x-large: 1060px;
  /*-------- Desktop devices*/
  $xx-large: 1250px;
  $xxx-large: 1440px;
  $high-quality: 1700px;
  /* z-index */
  $z-back: -1;
  $z-none: 0;
  $z-floor: 1;
  $z-medium: 10;
  $z-up: 50;
  $z-top: 100;
  $z-super: 999;
  
  /* Colors - static */
  $banana-mania: #ffeeb3;
  $black-russian: #0c0c0d;
  $black: #000000;
  $blackcurrant: #080311;
  $blackcurrant: #170339;
  $blue-violet: #9610ff;
  $caribbean-green: #00eca5;
  $cello: #3f4f66;
  $dodger-blue: #1f7cea;
  $freespeechmagenta: #dd52dd;
  $golden-brown: #997700;
  $ghost-white: #f6f7fc;
  $jade: #00b880;
  $jaguar: #0d0c0f;
  $jaguar_two: #282829;
  $lavender-blue: #bdbdff;
  $light-red: #fdcabf; //junior
  $maire: #1d1d1b;
  $melrose: #bdbdff; //master
  $persian-indigo: #330072;
  $persimmon: #ff6849; 
  $cornflower-blue: #9d9da1;//trainee
  $purple-bc-input: rgba(64, 29, 205, 0.2);
  $purple-color-input: rgb(82, 82, 85);
  $purple-heart-o2: rgba(100, 41, 205, 0.2);
  $purple-heart-o6: rgba(100, 41, 205, 0.6);
  $purple-heart-oo5: rgba(100, 41, 205, 0.05);
  $purple-heart-o8: rgba(100, 41, 205, 0.08);
  $purple-heart: #6429cd;
  $sail: #a4cae8;
  $seal-brown: #131313;
  $torch-red: #ed0039;
  $ultramarine-blue: #5452f6; //senior
  $venetian-red: #b8002c;
  $watercourse: #00b880;
  $white: #ffffff;
  $wild-watermelon: #ff527b;
  $yellow-orange: #f8af3c; //advanced
  $yellow: #8c004b;
  $jacarta: #3b355b;
  $light-grey: #e8e8ed;
  $placeholder-color: rgba(157, 157, 161, 0.6);
  $easy-difficulty: #4646ff;
  $medium-difficulty: #7500a0;
  $insane-difficulty: #d8d8d6;
  $easy-background-difficulty: #dfdfff;
  $medium-background-difficulty: #f3d7ff;
  $insane-background-difficulty: #030302;
  
  /* operational variables*/
  $soft-red-error-color: $wild-watermelon;
  $soft-green-sucess-color: $caribbean-green;
  $red-error-color: $torch-red;
  
  $gun-powder: #4d4d4f;
  $tangerine-yellow: #ffca10;
  $background: #1a0938;
  
  /* Shadows */
  
  $purple-shadow: inset 0px 0px 30px rgb(189 189 255 / 30%);
  
  // Vars Colors
  :root {
    --color-primary: #6429cd;
    --color-white: #fff;
    --color-background: #1a0938;
    --color-line: #5924b8;
    --color-pressed: #4e1ea4;
  }