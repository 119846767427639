@import '../../../styles/variables.scss';
textarea{
    width: 100%;
    height: 100px;
    min-height: 74px;
    resize: vertical;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background: #6429CD14 0% 0% no-repeat padding-box;
    border: 1px solid $strong-purple;
    border-radius: 14px;
    opacity: 1;
    padding: 12px 20px;
    overflow: hidden;
    box-sizing: border-box;
    color: $secondaryFontGray;
    margin-top: 10px;
}
textarea:focus{
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: 0px 5px 10px rgba(100, 41, 205, 0.6), inset 0px 0px 6px rgba(189, 189, 255, 0.6);
}


.countChar {
    font-size: 12px;
    color: $persian-indigo;
}

