@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.m-requestContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.m-requestContainer.approve {
    justify-content:space-between;
}

.m-requestContainer_span {
        flex-direction: column;
        display: flex;
        margin: 0.8em 0em;
    &--text {
        color: $persian-indigo;
        font-size: $fontSizeLarge;
        margin: 0.4em 0em;
    }
}

.m-requestContainer_span-date {
    display: flex;
    width: 50%;
    flex-direction: column;
    &--text {
        color: $secondaryFontGray;
    }
    .SpanGray {
        color: #330072;
    }
}

.m-requestContainer-date {
    display: flex;
    padding: 1rem 0rem;
    flex-wrap: wrap;
    align-content: space-evenly;
}


@include tablet {
    .m-requestContainer {
        flex-direction: row;
        margin-left: 0;
        padding: 0;
        align-items: center; 
    }
    
    .m-requestContainer_span {
        width: 40%;
        padding: 0.8rem 0rem;
        margin: 0 0.8em;
        align-items: center;
    }
    
    .m-requestContainer.approve {
        flex-flow: row wrap;
        justify-content: center;      
    }
}

@include mobile {
    .m-requestContainer_span {
        width: 90%; 
    }

    .m-requestContainer_span-date {
        flex-direction: column;
        display: flex;
        margin: 0.5em 0.8em;
        &--text {
            color: $secondaryFontGray;
        }
    }
}