@import "../../../styles/variables.scss";

.error-container {
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $primary-purple
  }

  p {
    font-weight: 600;
  }
}
