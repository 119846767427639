@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

$time-1: 10s;
$time-2: 10s;
$time-3: 10s;

@function particles($max) {
    $val: (
        0 0 0 0 #99999b
    );
$vw: 0;
$vh: 0;

@for $i from 1 through $max {
    $vw: random(100) - 50 + vw;
    $vh: random(100) - 50 + vh;
    $val: #{$val},
        #{$vw} #{$vh} #535355,
        #{$vw} #{$vh} 3px 0 #828282;
}

@return $val;
}

@mixin particles($max) {
    box-shadow: particles($max);
    border-radius: 50%;
}

.container-lines {
    margin-top: 10px;
    max-width: 450px;
    width: 70%;
    min-width: fit-content;
    height: 2px;
    background-color: $lavender-blue;
    display: flex;
    justify-content: space-between;

    &__line {
        width: 28px;
        height: 6px;
        background-color: $lavender-blue;
        position: relative;
        border-radius: 4px;
        top: -2px;
        box-shadow: 0px 0px 10px $purple-heart;
    }
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #1F0D3F;

    &__contact {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFF;
    }

    &__link {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #BDBDFF;
    }

    &__titles {
        color: #FFF;
        text-align: center;

        h1 {
            margin: 0;
            font-size: 46px;
            font-weight: 900;
            line-height: 55px;
            letter-spacing: 0em;
        }

        h4 {
            margin: 0;
            font-size: 23px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: 0em;
        }
    }

    &__wrapper {
        position: fixed;
        width: 100%;
        height: 100%;

        &__particle-first {
            position: fixed;
            top: 50%;
            left: 50%;
            height: 3px;
            width: 3px;
            @include particles(75);
            animation: zoom $time-1 linear forwards infinite;
        }

        &__particle-second {
            position: fixed;
            top: 50%;
            left: 50%;
            animation: zoom $time-2 linear forwards infinite;
            @include particles(100);
            height: 2px;
            width: 2px;
        }

        &__particle-third {
            position: fixed;
            top: 50%;
            left: 50%;
            animation: zoom $time-3 linear forwards infinite;
            @include particles(75);
            height: 1px;
            width: 1px;
        }
    }

    &__logo {
        position: relative;
        width: 250px;
        height: 250px;
        margin-bottom: 60px;

        &__shadow {
            opacity: 80;
            transition: opacity 0.5s linear;
            position: absolute;
            content: '';
            top: 20px;
            left: 20px;
            right: 0;
            z-index: 0;
            height: 80%;
            width: 80%;
            transform: scale(0.9) translateZ(0);
            filter: blur(45px);
            border-radius: 100px;
            background: linear-gradient(to left, #6429cd, #dd52dd);
            background-size: 200% 200%;
            animation:
                boxshadow 1.75s,
                animate-glow 2.5s linear infinite;
        }

        &__ball {
            opacity: 0;
            width: 250px;
            height: 250px;
            position: absolute;
            border-radius: 50%;
            border: 12px solid $white;
            transform: scale(0);

            animation: big-circle 1s 1s forwards;
        }

        &__one {
            opacity: 0;
            width: 24px;
            height: 24px;
            background: $white;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            top: 0;
            left: 95px;
            bottom: 48px;

            animation: little-circle 1s forwards;
        }

        &__two {
            border: 10px solid $white;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 49px;
            left: 77px;
        }

        &__three {
            opacity: 0;
            border: 10px solid $white;
            width: 62px;
            height: 62px;
            border-radius: 50%;
            position: absolute;
            margin: auto;
            top: 0;
            left: 76px;
            bottom: 46px;

            animation: medium-circle 1s forwards;
        }
    }

    &__container {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 42px;
        z-index: 2;

        h1 {
            text-align: center;
            font-weight: 600;
            font-size: 36px;
            color: $white;
        }

        h3,
        p {
            color: $white;
        }
    }
}

.login-button {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 314.61px;
    width: 100%;
    height: 70px;
    min-width: fit-content;
    min-height: auto;

    font-size: 16px;
    font-weight: 700;
    border-radius: 50px;
    padding: 16px 40px;
    gap: 10px;

    cursor: pointer;
}

.quaternary {
    background-color: rgba(100 41 205 / 20%);
    color: $white;
    border: 1px solid var(--color-primary);

    &:hover {
        box-shadow:
            0 0 15px var(--color-primary),
            inset 0 0 20px rgba(100 41 205 / 80%);
    }

    &:active {
        background-color: var(--color-primary);
        box-shadow:
            0 0 15px var(--color-primary),
            inset 0 0 10px rgba(189 189 255 / 50%);
    }
}

@keyframes big-circle {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(100%);
        opacity: 1;
    }
}

@keyframes medium-circle {
    0% {
        left: 76px;
        bottom: 46px;
    }

    100% {
        left: 103px;
        bottom: -93px;
        opacity: 1;
    }
}

@keyframes little-circle {
    0% {
        left: 95px;
        bottom: 48px;
    }

    100% {
        left: 144px;
        bottom: 135px;
        opacity: 1;
    }
}

@keyframes animate-glow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}

@keyframes boxshadow {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    80% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(100%);
        opacity: 1;
    }
}