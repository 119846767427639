@import "../../../styles/variables.scss";

.round-button {
    display: block;
    width: 32px;
    height: 32px;
    background: $ghost-white;
    border: none !important;

    .iconRoundButton {
        width: 16px;
        height: 16px;
    }

    &:hover {
        box-shadow: 0px 0px 20px 0px $softPurple-background;
      }
}
