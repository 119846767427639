@import "../../../styles/variables.scss";
.content-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background-color: $loaderBackground;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  .loader {
    width: 400px;
    height: 300px;   
  }
}

.loading {
  display: flex;
}
