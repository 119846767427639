@import '../../../styles/mixins.scss';

.toolkit {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 1rem;
  float: right;
}

.nonfloating-toolkit {
  float: none !important;
  margin-bottom: 1.5rem;
  margin-right: 7%;
  margin-top: -5rem;

  @include tablet-large {
    .searchContainer{
      width: 200px;
    }
    .m-container__input{
      width: 200px;
    }
  }
}

.toolkit *:not(:first-child) {
  margin-right: 1rem;
}


@media (max-width: 800px){ 
  .toolkit {
    float: none;
    gap: 5px;
  }

  .toolkit *:not(:first-child) {
    margin-right: 0;
  }
}

@include mobile {
  .toolkit {
    flex-direction: column;
    margin: auto;
    gap: 5px;
    float: none;
    margin-top: 1.5rem;
  }
}
