@import '../../../styles/variables.scss';

.button__container {
    text-align: center;
}


.send-client__button{
    --background : white;
    --color : #6429CD;
    --padding-horizontal: 0px;
    --height: 40px;
}

.button__container pds-button {
    max-width: 184px;
    max-height: 40px;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 20px;

}


@media (max-width: 700px) {
    .button__container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .button__container Button {
        width: 90%;
        font-size: 1rem;
    }
}