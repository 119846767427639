@import '../../../styles/variables.scss';


.list-file {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;

    .input-file-interno__img-upload {
        margin: 0 auto;
    }

    .padding-without-file{
        min-height: 60px;
    }

    .container-upload {
        padding: 0;
    }

    &.is-active {
        background-color: $shadowWhite;
    }

    &__internal-file {
        color: $FontGray;
        border-bottom: 1px solid #AB88E759 ;
        padding: 10px 10px 10px 16px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 4;
        width: 100%;

        img{
            width: auto;
            height: auto;
        }
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-top: -10px;
        margin-bottom: 10px;
        padding: 0%;
    }

    &__internal-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $persian-indigo;
        font-weight: 600;
    }

    &__internal-text:hover {
        color: $primary-purple;
        cursor: pointer;
    }

    &__internal-button{
        border: none;
        background-color: transparent;
        outline: none;
        display: flex;
        align-items: center;
        color: $primary-purple;
        margin: 0px;
        width: auto;
        font-weight: bold;
        z-index: 5;
        cursor: pointer;
    }

}


.message-list{
    &__bold{
        justify-self: center;
        align-self: center;
        font-weight: 500;
        color: $secondaryFontGray;
        padding: 8px 15px;
        font-size: 12px;
    }
}


