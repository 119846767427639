@import "../../../styles/variables.scss";

.language {
    position: absolute;
    z-index: 2;

    display: grid;
    justify-items: end;
    gap: 6px;

    top: 5%;
    right: 5%;

    &__button {
        display: grid;
        justify-content: center;
        align-items: center;
        align-content: center;

        width: 26px;
        height: 26px;
        border-radius: 50px;
        border: none;
        cursor: pointer;

        background-color: $primaryWhite;

        &-icon {
            width: 16px;
            height: 16px;
        }
    }

    &__selector {
        position: absolute;
        z-index: 2;

        background-color: #F6F7FC;
        border-radius: 20px;

        width: 184px;
        height: 80px;
        min-width: fit-content;
        min-height: fit-content;

        &-icon {
            display: flex;
            align-items: flex-end;
            width: 16px;
            height: 16px;
        }

        &-spanish {
            font-family: 'Poppins', sans-serif;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: start;
            padding: 9px 13px 9px 13px;
            border-radius: 16px 16px 0px 0px;
            background-color: #F6F7FC;

            color: #330072;
            font-size: 16px;
            font-weight: 400;

            border-top: 2px solid #6429CD;
            border-left: 2px solid #6429CD;
            border-right: 2px solid #6429CD;
            border-bottom: none;

            height: 50%;
            width: 100%;

            cursor: pointer;

            &:hover {
                color: #FFFFFF;
                background-color: #6429CD;
                box-shadow: 0 0 20px #fff9 inset, 0 12px 24px #6429cd99;
            }
        }

        &-english {
            font-family: 'Poppins', sans-serif;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: start;
            padding: 9px 13px 9px 13px;
            border-radius: 0px 0px 16px 16px;
            background-color: #F6F7FC;

            color: #330072;
            font-size: 16px;
            font-weight: 400;

            border-top: none;
            border-left: 2px solid #6429CD;
            border-right: 2px solid #6429CD;
            border-bottom: 2px solid #6429CD;

            height: 50%;
            width: 100%;

            cursor: pointer;

            &:hover {
                color: #FFFFFF;
                background-color: #6429CD;
                box-shadow: 0 0 20px #fff9 inset, 0 12px 24px #6429cd99;
            }
        }
    }
}