@import '../../../styles/variables.scss';


.container-upload {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    &.border-dashed {
        border-width: 2px;
        border-style: dashed;
        border-color: rgba(171, 136, 231, 1);
        border-radius: 10px;
    }
}