 /* Función para media queries */
@import "./variables.scss";

@mixin from($size) {
    @media (min-width: $size) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$small}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$medium}) {
        @content;
    }
}

@mixin tablet-large {
    @media (max-width: #{$x-large}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$xx-large}) {
        @content;
    }
}
