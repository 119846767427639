@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.aside {
    background-color: $persian-indigo;
    position: fixed;
    top: 0;
    z-index: 6;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 22%;

    .items{
        @media screen and (max-height: 700px) {
            margin-top: 83px;
        }
    }

    @media screen and (max-width: 1366px) {
        width: 22%;
    }
}

.aside nav{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.a a {
    text-decoration: none;
}

.content-logo-aside {
    width: 100%;
    height: 172px;
    position: relative;
}

.content-logo-aside .content-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.content-image .content-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25%;
    left: 42%;

    @media screen and (min-width: 1920px) {
        left: 34%;
    }
}

.content-image .content-image-container :first-child {
    margin-right: 13px;
}

.content-image .content-image-container:nth-child(-n+2) {
    width: 50px;
    height: 50px;
}

.content-items {
    width: 100%;
    height: 180px;
}

a {
    text-decoration: none;
    color: $persian-indigo;
    font-weight: 400;
    .redirect {
        width: 85%;
        height: 30px;
        margin-top: 5px;
        border-radius: 16px;
        margin-left: 26px;
        display: flex;
    }
    span {
        margin-left: 8px;
        font-size: $fontSizeLarge;
    }
    a:focus {
        text-decoration: none;
        color: white;
        font-weight: 600;
    }
}

.redirect:hover {
    background-color: $primary-purple;
    color: $primaryWhite;
}

.icon {
    width: 11.8%;
    margin-top: 2px;
    margin-left: 10px;
}

.icon2 {
    width: 12% !important;
    // Este icono es temporal
}

.content-footer-aside {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
    margin-bottom: 50px;
    width: 100%;

}

.disabled {
    display: none;
}

button .active {
    background-color: $primary-purple;
}

.content-button {
    display: none;
    margin-top: 250px;
    margin-left: 30px;
}


@include tablet {
    .aside {
        width: 10%;
        height: 30px;
        align-items: center;
        background-color: inherit;

        nav {
            width: 100vw;
            height: 100%;
            background-color: $purple-background;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-around;
            position: fixed;
            top: 0;
            left: 0;
            transform: scalex(0);
            transform-origin: left;
            transition: transform 300ms ease;
        }

        .content-logo-aside .content-image {
            margin-left: 0;
            margin-top: 10px;
        }
    }
    .sidebarLink {
        display: flex;
        justify-content: center;
        min-width: 190px;
        margin-left: 0;
    }
    .aside-content {
        width: 100%;
        z-index: 10;
        display: flex;
        background-color: $primary-purple;
    }
    .content-button {
        margin: 0px;
        padding-top: 10px;
        justify-content: center;
        display: flex;
        width: 40px;
        z-index: 5;
        position: absolute; /* Cambio a position absolute */
        top: 0px;
        left: 0px;

        img{
            width: 38px;
            margin: 30% 80%;

            @include mobile {
                margin: 30% 0;
            }
        }

        button {
            cursor: pointer;
            background: none;
            border: 0;
            color: inherit;
        }
    }
    nav {
        .content-logo-aside {
            height: auto;
        }
        .content-footer-aside {
            margin-top: 0%;
            margin-bottom: 50px;
        }
    }
    .content-button-open button:focus,
    .content-button button:focus {
        outline: 0;
    }
    .content-button-open {
        left: 65vw;
        top: 20px;
        position: absolute;
        transition: left 400ms ease-in-out;
    }
    .content-button-open + nav {
        display: block;
        width: 80%;
        height: 100%;
        background-color: $persian-indigo;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        transform: scale(1);
        transform-origin: left;
        transition: all 400ms ease-in-out;
        .content-items {
            width: 50%;
            .sidebarLink {
                margin-left: 25%;
            }
        }
    }
    .items {
        align-self: stretch;
    }
    .content-footer-aside {
        align-self: flex-start;
        justify-self: flex-end;
    }
}
