@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.form-checkbox {
    width: 85%;
    margin-left: 8%;
    margin-top: 32px;
    padding: 32px;
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;

    .HeaderBody{
        padding: 0;
        h3{
            font-size: 21px;
        }
    }
}

.horizontal{
    display: flex;
    flex-wrap: wrap;
    gap: 5%;

    .horizontal_input_text{
        margin: 1rem 0rem;
        width: 30%;
        position: relative;
    }

}

.checkbox--custom{
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: $fontSizeLarge;
    width: 30%;
    border-bottom: 1px solid #9747FF38;
}

.checkbox--withLessMargin {
    margin: 0.5rem 0rem;
}

.m-div-table {
    border-spacing: 5px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  
    width: 100%;
    padding-bottom: 10px;
    column-gap: 2%;
}

.m-div-table-row {
    display: flex;
    flex-direction: column;
    width: 48%;
    clear: both;
    margin: auto;
    margin-left: 0;
    margin-right: 0;

    &__col {
        float: left;
        display: table-column;
        margin: 1rem 0rem;
    }
    &.one {
        width: 100% !important;
    }
}

@include mobile {
    .m-div-table-row {
        margin: 0 .5% 5%;
    
        &__col {
            float: left;
            display: table-column;
            margin: 1rem 0rem;
        }
    }

    .checkbox--custom{
        margin: 0 1em;
        width: 100%;
        padding: 0;

        label{
            margin: 5%;
        }
        label ::before{
            margin: 0;
        }
    }
    
    .horizontal{
        padding-top: 5%;
    }
}

@include tablet {
    .m-div-table {
        height: auto;
        margin: 0;
        width: 100%;

        .m-div-table-row{
            width: 100%;
            margin-bottom: 1rem;
            &__col {
                float: unset;
                display: block;
                width: 100%;
                margin-bottom: 0.5rem;
                margin-top: unset;
                text-align: center;
            }
        }
    }
}

