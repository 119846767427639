.BrandHeader{

    .BrandHeaderTitleContainer{
        display: flex;
        margin-left: 5%;
        .Header{
            width: 85%;
        }
        .Primary{
            margin-left: 0;
            width: 100%;
            .TitleContent{
                h2 {
                    padding-left: 12px;
                }
            }
            hr{
                position: relative;
                left: -4%;
                width: 105%;
            }
        }
    }
    
    .BrandHeaderButtonContainer{
        display: flex;
        margin-left: 3%;
        z-index: 2;
    }
    
}

