@import "../../../styles/variables.scss";

.Primary {
  display: flex;
  height: 70px;
  color: $primary-purple;
  width: 100%;
}

.ImageContent {
  width: 3.5%;
  min-width: 30px;
}

.Secondary {
  margin-top: -20px;
  color: $secondaryFontGray;
}

.TitleContent{
  .title{
    margin: 0;
  }
  .description{
    padding-left: 0 !important;
  }
}


