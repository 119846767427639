.HeaderContainer{
  display: flex;
  position: relative;
  z-index: 2;
  .HeaderInformation__button{
    display: flex;
  }

  .HeaderEditUser{
    display: flex;
    width: 100%;
    .information__description{
      margin-bottom: 1rem;
    }
    .primary{
      .title__content{
        position: relative;
        top:15px;
        margin-left: 6%;
      }
    }
  }
}