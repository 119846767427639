@import "../../../styles/variables.scss";


.o-footer__table {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 8%;
}

.o-footer__table nav {
  text-align: right;
}

@media (max-width: 766px) {
  .o-footer__table {
    flex-direction: column-reverse !important;
  }
}
