@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.select-container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 !important;

  @include tablet {
    justify-content: center;
  }
}

.select-container span {
  font-size: $fontSizeLarge;
  font-weight: 400;
  color: #e7ddf8;
  white-space: nowrap;
  margin-right: 4px;
}

.select-options {
  position: absolute;
  z-index: 1;
  top: 100%;
  width: 129px;
  border: solid 1px $strong-purple;
  border-radius: 16px;
  box-shadow: 0px 11px 24px 10px rgba(100, 41, 205, 0.24);
}

.select-option {
  width: 100%;
  height: 40px;
  background-color: $purple-background;
  color: $primaryWhite;
  font-size: $fontSizeLarge;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  border: none;

  &:first-child {
    border-radius: 16px 16px 0 0;
  }

  &:last-child {
    border-radius: 0 0 16px 16px;
  }

  &:hover {
    background-color: $strong-purple;
    box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.6) inset;
  }

  &.selected {
    background-color: $strong-purple;
    box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.6) inset;
  }
}

.select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}