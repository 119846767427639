@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.quick-access {
    display: grid;
    justify-items: center;
    place-content: center;
    position: relative;

    color: white;
    border-radius: 24px;
    background: #44009999;
    border: 1px solid #6429CD;
    box-shadow: 0px 0px 30px 0px #BDBDFF4D inset;


    margin: 1px 10px;
    width: 140px;
    max-width: 164px;
    height: 140px;
    max-height: 164px;

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: center;
    }

    @include tablet {
        width: 100%;
    }

    img {
        max-width: 52px;
        max-height: 52px;
    }

    transition: all 0.2s ease-in-out;

    &:hover {
        filter: drop-shadow(-1px 1px 20px #6429CD);
        cursor: pointer;
        transition: filter 0.3s ease-in-out;

        img.selector {
            transition: display 0.5s ease-in-out;
            display: block;
        }
    }
}

.quick-access img.selector {
    position: absolute;
    top: -3.9%;
    width: 150px;
    height: 150px;
    max-width: 164px;
    max-height: 164px;
    border-radius: 24px;
    display: none;
}