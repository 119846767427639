@import "../../../styles/variables.scss";

.primary {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 85%;
    margin-left: 8%;
}

.image__content {
    margin-right: 1%;
    width: auto;
    margin-top: 25px;
}

.title__content>h1 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;

}

.information__description {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

hr {
    width: 100%;
    height: 0px;

    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width:575px) {
    .image__content {
        width: 5%;
        margin-right: 3%;
    }
}