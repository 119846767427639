.dataClients__header{
    width: 100%;
    background-color: gainsboro;
    padding: 0;
    margin: 0;
}

.dataClients__header h4{
    margin-block-start: 0;
    margin-block-end: 0;
}

.dataClients__body__finder{
    width: 100%;
}
.dataClients__body__container{
    width: 100%;
}

