@import "../../../styles/variables.scss";

.history_fields {
    background: $softPurple-background 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 32px;
    margin: 20px 0 30px;

    .information {
        padding: 0;
        
        h3 {
            color: $strong-purple;
            font-size: 21px;
            margin: 0;
        }

        .clients-links {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
      
            .clients-links-col {
                display: flex;
                flex-direction: column;
                width: 40%;
    
                hr {
                    border: 1px solid #9747FF38;
                }
    
                a{
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                }
    
                a[target=_blank]:after {
                    content: url('../../../images/open_in_new.svg');
                    margin-left: auto;
                }
            }
        }

    }   

}