@import "../../../../styles/variables.scss";
.TextSearchNombreCliente input {
  background: url(../../../../images/buscar.svg) no-repeat right;
  border-bottom: solid $secondaryGray 1px;
  width: 100%;
  height: 30px;
}
.input-text__label{
  color: $persian-indigo;
  font-size: $fontSizeLarge;
  font-weight: 700;
  position: relative;
  left: 5px;
  top: -2px;
}
.input-text__label--disabled{
  color: #9d9da1;
  font-size: $fontSizeLarge;
  font-weight: 700;
  position: relative;
  top: -2px;
}
