.header {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;
    height: 30px;
    background-color: transparent;

    z-index: 10;

    &__lineLeft {
        height: 30px;
        position: relative;
        background-color: #4a1e97;
        width: 93%;
        @media (max-width: 800px) {
            display: none;
        }
    }

    &__lineRight {
        height: 30px;
        position: relative;
        background-color: #4a1e97;
        width: 7%;
        @media (max-width: 800px) {
        display: none;
    }

        &-squeareRight::before {
            content: '';
            height: 20px;
            width: 20px;
            background: #4a1e97;
            position: absolute;
            top: 30px;
            left: 0;
        }
    }

    &__links {
        position: absolute;
        right: 7%;
        &-container {
            display: flex;
            align-items: center;
            position: relative;
            gap: 16px;
            height: 85px;
            padding: 10px 30px;
            border-radius: 0 0 20px 20px;
            background: #4a1e97;

            &-squeare {
                content: '';
                height: 20px;
                width: 20px;
                background: #4a1e97;
                position: absolute;
                top: 30px;
                left: -20px;
                @media (max-width: 800px) {
                    display: none;
                }
            }

            
        @media (max-width: 800px) {
            background-color: transparent;
        }
        &::before {
            content: '';
            height: 40px;
            width: 40px;
            background: #1f0d3f;
            position: absolute;
            top: 30px;
            right: -40px;
            border-radius: 50%;
            z-index: 1;
        }

        &::after {
            content: '';
            height: 40px;
            width: 40px;
            background: #1f0d3f;
            position: absolute;
            top: 30px;
            left: -40px;
            border-radius: 50%;
            @media (max-width: 800px) {
                display: none;
            }
        }
        }
    }
}