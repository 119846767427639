@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.check-invoice {
  width: 85%;
  margin-left: 8%;


  .divConsecutivo {
    display: flex;

    .inicialCon {
      height: 20px;
      margin-top: 5px;
      color: $secondaryFontGray;
    }
  }

  &__title {
    h3 {
      font-weight: 700 !important;
      margin: 0rem;
      font-size: 21px;
    }
  }

  .TextAreaBlock {
    .SpanGray {
      color: $persian-indigo;
    }
  }

  &__title {
    margin: 0rem;
  }

  &__inputs-container {
    border-radius: 20px;
    padding: 2rem !important;
    color: $strong-purple;
    background-color: $softPurple-background;

    .inputs-text {
      margin-top: 1rem;

      // @include from($medium) {
      //     display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     margin-bottom: 2rem;
      // }

      // .input-text {
      //     margin: 1rem 0;
      //     @include from($medium) {
      //         width: 40%;
      //     }
      // }
    }

    .align_item_center {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-around;
    }

    .containerBlocks {
      height: 100px;
      border: 1px solid $strong-purple;
      border-radius: 10px;
      background: #F8F8F8;
      box-shadow: 0px 0px 45px 25px rgba(100, 41, 205, 0.25) inset;

      .containerTextTwoBlocks {
        display: flex;
        text-align: center;
        margin-top: 50px;

        .input-text__placeholder--label-trm {
          display: flex;
          width: fit-content;
          color: $persian-indigo;
          bottom: 30px;
          right: 5%;
          font-size: 14px;
          font-weight: 700;
        }

        .input-text input {
          padding: 0px;
          font-size: 14px;
          width: 45%;
          text-align: center;
          background-color: $backgroundPurple;
          border: $strong-purple 1px solid;
          border-radius: 50px;
        }

        input:disabled {
          color: $persian-indigo;
          background-color: transparent;
          font-weight: 600;
          width: 45%;
        }

        .approval-invoice {
          &__inputs {
            padding-top: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;

            &--files {
              width: 50%;
              min-width: 300px;
            }
          }

          &--texts {
            width: 50%;
            min-width: 300px;
            justify-content: center;
            align-items: center;

            .input-text {
              @include from($medium) {
                max-width: 200px;
                margin-bottom: 2rem;
              }
            }

            .input-text input {
              border-bottom: 1px solid $backgroundGray;
              text-align: end;
              padding-right: 1.5rem;
            }

            .divConsecutivo {
              .input-text input {
                border-bottom: 1px solid $backgroundGray;
                text-align: start;
                padding-right: 1.5rem;
              }
            }
          }

          .InputFileBlock {
            .input-file-block__texto {
              @include from($medium) {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .approval-invoice {
    &__inputs {
      &--files {
        width: 50%;
        min-width: 300px;
      }
    }
  }

  &__button-container {
    margin-top: 2rem;

    .white {
      --background: #F6F7FC;
      --color: #6429CD;
    }

    .outlined {
      --background: transparent;
      --border: 1px solid #F6F7FC;
      --color: $ghost-white;
    }

    button+button {
      margin-top: 1rem;
    }

    &-button {
      min-width: 20%;

      pds-button {
        width: 100%;
      }
    }

    @include from($medium) {
      width: 100%;
      display: flex;
      margin: auto;
      padding: 52px 0;
      justify-content: space-evenly;

      button+button {
        margin-top: 0;
      }
    }

    @include tablet-large {
      width: 85%;
    }

    @include mobile {
      width: auto;
    }
  }
}