@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';


.form {
    width: 85%;
    padding: 32px;
    margin-left: 8%;
    margin-top: 32px;
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;

    hr {
        width: 100% !important;
        border: none;
        border-top: 1px solid #9747FF38;
    }

    .ContentBody {
        padding: 0;

        .PolicyData {
            .title__finder {
                color: #330072;
                font-size: 14px;
                font-weight: 700;
            }

            .tittle__finder-disabled {
                color: #828285 !important;
            }

            .dataClients__body {
                display: flex;
                padding: 0;
                gap: 24px;
                padding-bottom: 24px;

                .select {
                    z-index: unset;
                }

                &__container {
                    width: 40%;
                }

                &__selected-container {
                    display: flex;
                    flex-wrap: wrap;
                    width: 60%;

                    & .main__pill__button[disabled] {
                        padding: 12px;
                        border: 1px solid $cornflower-blue;
                        background-color: rgba(157, 157, 161, 0.0784313725);
                        font-size: 14px;
                        color: #828285;
                    }
                }
            }
        }
    }
}

.HeaderBody.relations {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    .RelationsTitle {
        width: 40%;

        h3 {
            font-size: 21px;
        }

    }

    .header-fields {
        width: 35%;

        pds-dropdown {
            position: relative;
            z-index: 1;
            --width: 100%;
            --bg-options: #1F0D3F;
        }
    }
}

.ContentBody {
    padding: 10px;
}

.SubsidiaryCard {
    width: 50%;
    padding: 0px 50px;
}

@include mobile {
    .HeaderBody.relations {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;

        .RelationsTitle {
            width: 100%;
        }

        .header-fields {
            .select {
                margin-top: 0;
            }
        }
    }
}