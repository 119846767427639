@import '../../../styles/variables.scss';
@import "../../../styles/mixins.scss";

.ContentConcepts {
    border-radius: 14px;
    width: 85%;
    margin-left: 8%;
    padding: 32px;
    margin-top: 32px;
    background: $softPurple-background;

    .conceptsHeader {
        border-bottom: 1px solid rgba(151, 71, 255, 0.22);
        padding-bottom: 21px;

        .titleConceptos {
            color: $strong-purple;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .titleConceptos h3 {
            margin: 0px 0px 30px 0px;
            font-size: 21px;
        }
        .concept-fields {
            width: 30%;
            display: inline-block;
            margin: 0px 15px 0px 0px;
    
        }
        .concept-fields-date {
            width: 18%;
            display: inline-block;
            margin: 0px 32px 0px 0px;    
        }
        .concept-fields-coin {
            width: 20%;
            display: inline-block;
            margin: 0px 15px 0px 0px;
        }
        .concept-dates-info{
            margin: 12px 0 0;
            display: flex;
            gap: 24px;
            align-items: center;
        }
        .header-subsidiary {
            width: 40%;
            p {
                margin-left: 15px;
            }
        }
    }
    .concept {
        margin: auto;
        border-bottom: 1px solid rgba(151, 71, 255, 0.22);
    }
    .Title h3 {
        margin-bottom: 9px;
    }
    .total {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        color: $strong-purple;
        h3 {
            margin-left: 180px;
            margin-right: 20px;
            font-size: 21px;

            @include tablet {
                margin-left: 80px;
            }
        }
    }
    .concept__add {
        border: none;
        background-color: transparent;
        font-family: 'Poppins', sans-serif;
        border: $strong-purple 1px solid;
        border-radius: 50px;
        padding: 6px 20px;
        outline: none;
        display: flex;
        align-items: center;
        color: $strong-purple;
        margin-left: auto;
        margin-top: 20px;
        width: auto;
        font-weight: 600;
        font-size: 14px;
        &:hover {
            background-color: $strong-purple;
            color: $ghost-white;
            box-shadow: 0px 0px 20px 0px #BDBDFF33 inset, 0px 0px 15px 0px #6429CD;
            cursor: pointer;
        }
    }
}

@media screen and (max-width:700px) {
    .body .header .titleConceptos{
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    .body .header .titleConceptos h3{
        margin-block-start: 0;
    }
    .header {
        padding: 5%;
        flex-direction: column;
        
    }
    
    .header-subsidiary {
        width: 100% !important;
    }
    .body {
        padding: 5%;
        .concept__add {
            padding-left: 5%;
            margin-left: 0px;
        }
        .totalFinal{
            margin-top: 20px;
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 5px;
            border-radius: 15px;
            h3{
                margin: 0;
                margin-bottom: 10px;
                font-size: 1.1rem;
            }
        }
        .footer {
            padding-left: 5%;
            justify-content: flex-start;
            
            // h3{
            //     margin-right: 50px;
            // }
        }
    }
}