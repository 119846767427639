@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.Body {
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;
    width: auto;
    height: auto;

    .body_generalInformation{
        background: $softPurple-background 0% 0% no-repeat padding-box;
        border-radius: 14px;
    }
}

.Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderBody {
    color: $strong-purple;
    padding: 32px 32px 0px;
    .Title{
        margin: 0;
        font-size: 21px;
    }
}

.Body hr {
    width: 97%;
    margin: 0.5rem;    
}

.n1 {
    color: $primary-purple;
}

.backButton {
    display: flex;
}

.goBack {
    width: 30%;
    height: auto;
}

.adjuntar {
    width: 35%;
    height: auto;
}

.ContentDates {
    display: flex;
    margin: 20px 0px;
}

.Date1 {
    margin-left: 30px;
}

.Date2 {
    margin-left: 15%;
}

.Conditions {
    margin-top: 32px;
}

.GeneralInformation{
    width: 85%;
    margin-left: 8%;

    .HeaderInformation{
        margin: 0 auto;
        justify-content: space-between;
        
        .HeaderInformation__title{    

            .Primary{
                color: $primaryWhite;
                margin-left: 0;
                position: relative;
                top: 0px;
            }

            hr{
                width: 100%;
                top: 0px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin-bottom: 26px;
            }
        }
                
    } 

}


.PolicyData {
    .bodyRequest{
        width: 100%;
        margin-left: auto;
        padding: 24px 32px 30px 32px;
    }
}


@include tablet{
    .HeaderBody {
        text-align: center;
        margin: 0px;
    }
    .ContentDates {
        display: flex;
        margin-top: 0px;
        flex-direction: column;
        margin-left: 1rem;
    }
    .Date1 {
        margin: 0px;
        padding: 0.8rem 0rem;
    }
    .Date2 {
        margin: 0px;
        padding: 0.8rem 0rem;
    }
    .ContentDataClient {
        display: flex;
    }
    .Header{
        margin: 0 auto;
        background-color: #fff;
    }


    .PolicyData {
        .HeaderDataRequest {
            text-align: center;
            
        }
        .HeaderDataRequest h3 {
            padding: .5rem;
        }
    }
}
