.scrollableDiv{
  height: 80vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  .table-scroll{
    .table-container{
      position: relative;
      top: 0;
    }
  }
}
