html {
    scroll-behavior: smooth;
}

.CrearFactura {
    display: flex;
    width: 100%;

}

.aside-content {
    width: 245px;
    background-color: white;
    margin-right: 2.7%;
    z-index: 1;
}

.factura-content {
    width: 75%;
    margin-top: 1%;
}

@media screen and (max-width:700px) {
    .factura-content {
        width: 85%;
    }
}