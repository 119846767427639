@import "./styles/variables.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./public/fonts/Poppins-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./public/fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
}

body {
    background-color: $purple-background;
    background-image: url('./images/background-texture.svg');
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.swal2-popup {
    border-radius: 25px !important;
}

.swal2-styled.swal2-confirm {
    margin-bottom: 5% !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
    color: $primaryWhite !important;
    background: $strong-purple !important;
    width: 30% !important;
    margin-top: 10px !important;
    border-radius: 30px !important;
}

.swal2-close {
    color: $strong-purple !important;
    font-weight: 700 !important;
}

.swal2-styled.swal2-deny {
    margin-bottom: 5% !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
    color: $primary-purple !important;
    border: 1px solid $primary-purple !important;
    background: $primaryWhite !important;
    width: 30% !important;
    margin-top: 10px !important;
    border-radius: 30px !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-deny:hover {
    background: $secondary-purple !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-confirm:hover {
    background: $primary-purple !important;
    border-color: $primary-purple !important;
    color: $primaryWhite !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"]{
    background-color: $primaryWhite !important;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
    background-color: $primaryWhite !important;
}

.swal2-title,
.swal2-content{
    color: $primaryWhite !important;
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
    background-color: transparent !important;
}


.swal2-icon.swal2-error{
    border-color: $torch-red !important;
    background-color: $torch-red !important;    
    box-shadow: 0px 12px 24px 0px #FF527B66;

}

.swal2-icon.swal2-warning{
    border-color: $primary-purple !important;
    background-color: $primary-purple !important;    
    box-shadow: 0px 12px 24px 0px $primary-purple;
    color: $primaryWhite !important;
}

.swal2-icon.swal2-success{
    background-color: $jade !important;
    border-color: $jade !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
    border-color: $jade !important;
    box-shadow: 0px 12px 24px 0px #00ECA566;

}


.swal2-styled.swal2-deny {
    margin-bottom: 5% !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
    width: 30% !important;
    margin-top: 10px !important;
}