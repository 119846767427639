@import "../../../styles/variables.scss";

.dialog-overlay {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  backdrop-filter: blur(1px);
  justify-content: center;
  padding: .625em;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: background-color .1s;
  background-color: $loaderBackground;
}

.dialog-container {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: $softPurple-background;
  font-family: inherit;
  font-size: 1rem;
  box-shadow: 0px 2px 5px 1px $darkShadow;
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.dialog-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-top: 1.5rem;
}

.dialog-buttons *:not(:first-child) {
  margin-right: 0.5rem;
}

.dialog-title {
  color: $primary-purple;
  font-size: 21px;
  display: flex;
  justify-content: center;
}

.dialog-title h3 {
  margin-top: 0px;
  margin-bottom: 1.5rem;
}

@media (max-width: 766px) {
  .dialog-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .dialog-buttons *:not(:first-child) {
    margin-right: 0;
    margin-top: 0.5rem;
  }
}
