@import "../../../styles/variables.scss";

.user-form__content{
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;
    width: 85%;
    height: auto;
    margin-top: 20px;
    margin-left: 8%;

    pds-input{
        width: 50%;
        padding: 0 10px;
    }
    pds-dropdown{
        width: 50%;
        padding: 0 10px;
        margin-right: 0;
    }
}

pds-dropdown{
    --width: 100%; 
    --bg-options:#1F0D3F;
    
    &.input-value {
        --color-placeholder: #330072;
    }
}

.information {
    position: relative;
    z-index: 1;
    padding: 32px;

    &__title {
        color: $strong-purple;
        font-size: 21px;
        font-weight: 700;
        margin: 0;
    }
}

.user-form__content__formBody {
    display: flex;
    justify-content: space-around;
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;

    .bodyRequest{
        padding: 0 10px;
    }
    
    .title__finder{
        color: $persian-indigo;
        font-weight: 700;
        font-size: $fontSizeLarge;
    }
}

.user-form__content__formButton{
    text-align: center;
    margin-top: 30px;

    pds-button{
        max-width: 184px;
        max-height: 40px;
        width: 100%;
        --background: #F6F7FC;
        --color: #6429CD;
    }
}


@media screen and (max-width:700px){
    .form__content__formBody{
        flex-direction: column !important;
    }
}

@media screen and (max-width:700px){
    .user-form__content__formBody{
        flex-direction: column !important;
        pds-input{
            width: 200px;
        }
    }
    .user-form__content__formBody div{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}