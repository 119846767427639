@import "../../../../styles/variables.scss";

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;

  height: 10px;
  width: 10px;
  background: url("../../../../images/x.svg");
  background-repeat: no-repeat;
  float: left;
  position: relative;
  margin-right: 30px;
  padding: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  background: url("../../../../images/x-hover.svg");
}

.input-text--text input {
  background: url(../../../../images/buscar.svg) no-repeat right;
  background-size: 45px;
  background-position: right -2px top -0.5px;
  background-color: #6429CD14;
  padding: 15px;
  border: 1px solid #6429CD ;
  border-radius: 50px;
  width: 100%;
  height: 40px;
}
.input-text--disabled input{
  background: url(../../../../images/search_disabled.svg) no-repeat right;
  background-position: right 4px top 3px;
  padding: 15px;
  border-radius: 50px;
  opacity:1;
  height: 40px;
  color:#9d9da1;
  background-color:#9d9da114;
  border: 1px solid #9d9da199 ;
  margin: 0px;
}

  .verification-digit {
    color: $FontGray;
    width: 20%;
    text-align: left;
    height: 40px;
    border: 1px solid $strong-purple ;
    background-color: #6429CD14;
    border-radius: 50px;
    margin-left: 1.6rem;
    padding-left: 1rem;
    padding-top: 0.4rem;
  }
  .verification-digit--disabled{
    width: 20%;
    text-align: left;
    height: 40px;
    border-radius: 50px;
    margin-left: 1.6rem;
    padding-left: 1rem;
    padding-top: 0.4rem;
    color:rgb(157 157 161);
    background-color:rgba(157, 157, 161, 0.08);
    border: 1px solid rgba(157, 157, 161, 0.6) ;
    }

  .verification-digit-label {
    font-size: $fontSizeLarge;
    font-weight: 700;
    color: $persian-indigo;
    position: absolute;
    top: -24px;
    right: 12%;
  }

  .verification-digit-label--disabled {
    font-size: $fontSizeLarge;
    font-weight: 700;
    color: rgb(157 157 161);
    position: absolute;
    top: -24px;
    right: 12%;
  }
  .clientnit-input {
    display: flex;
    flex-direction: row;
    align-items: center;

  input {
    width: 80%;
    flex-grow: 2;
  }

  .input-text__placeholder {
    width: 80%;
    margin-top: 0.4rem;
  }
}
