@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.form__content {
    background: $softPurple-background;
    box-shadow: 0px 3px 10px $shadowWhite;
    border-radius: 10px;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 1%;
}

.information {
    padding: 3%;
    padding-bottom: 0px;
    padding-top: unset;

    &__title {
        color: $strong-purple;
        font-size: 21px;
        font-weight: 700;
    }
}

pds-input {
    color: #330072;
}

pds-dropdown{
    &.input-value {
        --color-placeholder: #330072;
    }
}

.input__text {
    margin:  0 0 4% 0;
    display: grid;
    grid-template-columns: (1fr 1fr);
    gap: 24px;

    &--grid-3cols {
        margin: 4% 0;
        display: grid;
        grid-template-columns: (1fr 1fr 1fr);
        gap: 24px;
    }
}

.input__40 {
    width: 40%;
    margin-right: 5%;
}

.input__30 {
    width: 30%;
    margin-right: 5%;
}

.input__10 {
    width: 10%;
    margin-right: 5%;
}

.input__20 {
    width: 20%;
    margin-right: 5%;
}

.hover:hover {
    border: solid 1px red;
    background-color: aqua;
}

.input__33 {
    width: 33%;
    margin-right: 5%;

    @include tablet {
        width: 50%;
        margin-right: 0;
        padding: 5px 10px 0 0;
    }

    @include mobile {
        padding: 0;
    }
}

.input__text-territory {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include tablet {
        flex-wrap: wrap;
    }
}

.input__50 {
    margin-right: 5%;
    width: 50%;
}

.input_25 {
    margin-right: 5%;
    width: 25%;
}

.input__100 {
    margin-right: 5%;
    width: 95%;
}

@media (max-width: 700px) {
    .form__content {
        padding: 5%;
    }
    .input__text {
        flex-direction: column;
    }
    .margin__bottom {
        margin-bottom: 10%;
        margin-right: 5%;
        width: 100%;

        @include mobile {
            margin-right: 0;
        }
    }
}