@import '../../../styles/variables.scss';

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .m-hide_element {
    visibility: hidden;
  }

  * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .paginationNumber, .paginationTotalText {
    cursor: pointer;
  }

  .paginationNumber, .paginationDots, .paginationTotalText {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: fit-content;
    min-height: auto;
    width: 26px;
    height: 26px;

    padding-left: 2.5px;
    padding-right: 2.5px;

    font-weight: 600;
    border-radius: 100%;
    font-size: $fontSizeLarge;
    color: $secondaryFontGray;
  }
}

.paginationIconContainer {
  padding: 0.5rem;
  cursor: pointer;
}

.paginationIcon {
  height: 12px;
  width: 12px;
}

.paginationText {
  font-size: $fontSizeNormal;
  color: $secondaryFontGray;
}

.currentPageContainer {
  flex: 0 !important;
  background: $primaryWhite;
  border: 1px $grayBorder solid;
  box-shadow: 0px 2px 2px 1px $darkShadow;
  border-radius: 8px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.2rem;
}

.select-currentpage {
  border-radius: 0.25rem;
  border: unset;
  font-size: $fontSizeExtraLarge;
  font-weight: 500;
  appearance: none;
  text-align-last: center;
  padding: 0.25rem;
  margin: 0 !important;
}

.select-currentpage:focus {
  box-shadow: 0 0 2pt 1pt $primary-purple;
  outline: none;
}

span.paginationNumber.active {
  background-color: $strong-purple;
  box-shadow: 0px 0px 6px 0px rgba(189, 189, 255, 0.6) inset;
  color: $primaryWhite;
}