@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.button {
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  background-repeat: no-repeat;
  background-size: 0% 0%;
  background-position: 50% 50%;
  transition: background-size 0.3s, color 0.3s;

  &.button-secondary {
    color: $strong-purple;
    font-size: $fontSizeLarge;
    background: $ghost-white;
    border: 1px solid $primary-purple;
    padding: 10px 43px;
    
    &:not(button:disabled):hover {
      color: $ghost-white;
      box-shadow: 1px 1px 20px #6429cd;
      background-color: rgb(183 143 255 / 66%);
      background-size: 100% 100%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &.button--primary {
    color: $primaryWhite;
    background: $strong-purple;
    border: none !important;

    &:not(button:disabled):hover {
      background: $primary-purple;
      color: $primaryWhite;
    }
  }

  &.button--google {
    color: $FontGray;
    border-color: $primaryWhite;
    box-shadow: 0px 3px 6px $shadowGray;
    display: flex;
    align-items: center;
    background-image: linear-gradient($secondaryGray, $secondaryGray);

    &:hover {
      background-color: $primaryWhite;
    }

    div {
      width: 15%;
      position: relative;
      left: -15%;
    }
  }

  &-addButton{
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    padding: 0 !important;
    background-color: $primary-purple !important;
    border: none;
    color: white !important;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    overflow: hidden;
    transform-origin: left !important; 

    .button-container{
      display: flex ;
      align-items: center;
    }

    .button-text{
      display: none;
      font-family: 'Poppins', sans-serif;
      font-size: 16px !important;
      font-weight: 600 !important;
      opacity: 0;
      white-space: nowrap;
      transition: opacity 0.3s ease-in-out;
    }

    .button-add {
      transition: transform 0.3s ease-in-out ;
    }

    &:hover{
      width: 250px;
      border-radius: 25px;
      .button-text{
        display: inline;
        opacity: 1;
      }
      .button-container{
        margin-left: 5px;
      }
      .Images {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px !important;
        height: 35px !important;
        .button-add {
          height: 25px !important;
          width: 25px !important;
        }
      }
      
    }

  }
}

// .button:disabled {
//   background: $disabledGray;
//   border: 1px solid $disabledGray;
//   color: #9a9a9a;
//   cursor: not-allowed;
// }
.iconButton {
  display: flex;
  align-items: center;
  margin: -5px;
}

.iconContainer {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-borderless {
  border: 1px solid transparent !important;
  background-color: transparent !important;
  font-size: $fontSizeNormal !important;
  font-weight: 500 !important;
  color: $primary-purple;

  &:hover {
    border: 1px solid $primary-purple !important;
    background-size: 100% 100% !important;
  }
}

.button-borderless.button-noHover {
  border: none !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $primary-purple;
  padding: 10px;
}

.button-reverseIcon .iconContainer {
  order: 2;
}