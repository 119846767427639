@import "../../../styles/mixins.scss";

.client-toolkit__search{
  margin-right: 7%;
}

.client-table {
  .table-container{
    margin-top: 76px;
  }
}
