@import "../../../styles/variables.scss";

.Primary {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 85%;
  margin-left: 8%;
}

.TitleContent>h2 {
  font-size: 26px;
  font-weight: 700;
  color: $primaryWhite;
  margin: 0;
}

.TitleContent>p {
  color: $primaryWhite;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.ImageContent {
  width: 3.5%;
  min-width: 30px;
}

.Secondary {
  margin-top: -20px;
}

.hideElement {
  display: none;
}