@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.checkbox--custom {
  input[type="checkbox"] {
    display: none;
  }
  
  label {
    display: flex;
    padding-left: 32px;
    position: relative;
    color: $persian-indigo;
  }
  
  label:before {
    top:1px;
    content: "";
    width: 17px;
    height: 17px;
    border: solid 1px $primary-purple;
    border-radius: 5px;
    position: absolute;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover{
      box-shadow: 0px 5px 10px rgba(100, 41, 205, 0.6), inset 0px 0px 6px rgba(189, 189, 255, 0.6);
    }
  }
  
  input[type="checkbox"]:checked + label:before {
    background-image: url('../../../images/check-square-solid.svg');
  
  }

  @include mobile {
    label {
      margin: 0 0 0 80px;
    }

    label:before {
      margin: 2px 0 0 -.7em;
    }
  }
}

.checkbox-vertical label {
  font-size: $fontSizeSmall;
  padding: 0 !important;
  padding-left: 1.2rem !important;
}

.checkbox-vertical label:before {
  left: 0 !important;
}
