.crear-cliente {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.aside-content {
    width: 18%;
    margin-right: 2.7%;
}

.client-content {
    // background-color: gray !important;
    width: 75%;
    margin-top: 1%;
}

@media screen and (max-width:700px) {
    .aside-content {
        width: 10%;
    }
    .client-content {
        width: 85%;
    }
}