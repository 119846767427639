@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.DataClient{
    background-color: $softPurple-background;
    border-radius: 14px;
    padding: 32px;
    margin-top: 32px;
    height: auto;
    .primary{
        position: relative;
        top:0;
        padding-top: 24px;
        width: 100%;
        margin: 0;
        
        .input-text{
            width: 100%;
        }
    }

}

.info-client{
    display: flex;
    gap: 24px;

    .info-secundary{
        width: 32%;
    }
}

.HeaderDataClient {
    color: $strong-purple;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.HeaderDataClient h3 {
    margin: 0;
    font-size: 21px;
}

.BodyDataClient {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.BodyDataClient.reduce {
    height: auto;
}

.primaryDate {
    display: flex;
    width: auto;
    gap: 30px;
}

.primary {
    width: 33%;
    justify-content: flex-start;
}

.secondary {
    width: 33%;
}

.secondaryDate {
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 0;
    gap: 24px;
}

.TextField {
    display: flex;
}

.margin {
    margin-top: 3px;
}

.marginRight {
    margin-right: 2% !important;
}

.hideInput {
    visibility: hidden;
}

@include mobile {
    .HeaderDocumentsClient{
        height: 2%;
    }
    .HeaderDocumentsClient h3{
        margin: 0;
    }
}

@include tablet {
    .HeaderDocumentsClient h3{
        margin: 0;
    }
    .BodyDataClient.reduce {
        height: auto;
    }
    .DataClient {
        width: 100%;
    }
    .HeaderDataClient {
        text-align: center;
    }
    .HeaderDataClient h3 {
        padding: 0.5rem;
    }
    .BodyDataClient {
        height: auto;
        padding-right: 2%;
    }
    
    .primaryDate {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        .primary {
            padding-top: 0;
        }
    }
    .margin__bottom {
        margin-bottom: 3%;
    }

    .secondary {
        width: 100%;
        margin-bottom: 3%;
    }
    .responsiveMargin {
        margin-top: 10px;
    }
    .HeaderDataClient h3{
        margin-block-end: 0em;
    }
    
}
