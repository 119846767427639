@import '../../../styles/variables.scss';


.input-file {
    border: solid 1px $strong-purple;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 140px;
    padding: 5px;
    background: $secondaryGray;
    border-radius: 10px;
    box-shadow: 0px 4px 80px 50px rgba(100, 41, 205, 0.25) inset;
    &.is-active {
        background-color: rgba(255, 255, 255, 0.05);
    }

    &__internal-text {
        display: flex;
        flex-direction: column;
    }

    &__message-bold {
        justify-content: center;
        align-items: center;
        justify-self: center;
        align-self: center;
        font-weight: 500;
        color: $secondaryFontGray;
        border-radius: 3px;
        padding: 8px 15px;
        font-size: 12px;
    }
}

.container-files {
    width: 100%;
}

.input-file-generic {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 12px;
    background: $secondaryGray;
    border-radius: 10px;
}

.input-file-interno-generic {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    word-wrap: break-word;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow: auto;
    min-height: 30px;
    font-weight: 600;
    box-sizing: border-box;
}

.input-file-interno__img-upload {
    width: 20px;
    height: 20px;
    display: grid;
    place-content: center;
}

.disable-input-block{
    pointer-events: none;
    color: $secondaryFontGray;
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    
    cursor: pointer;
    opacity: 0;
    &:focus {
        outline: none;
    }
}


.input-file-interno__archivos {
    width: 100%;
    display: flex;
}

.input-file-interno__archivos ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
}

.input-file-interno__archivos_archivo {
    color: $FontGray;
    font-size: 8pt;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    width: 100%;
}

.input-file-interno__archivos_archivo_text:hover {
    color: $primary-purple;
    cursor: pointer;
}

.input-file-interno__archivos_boton:hover {
    transition: all .6s ease;
}

.input-file-interno__archivos_archivo_text {
    width: 80%;
    color: $primary-purple;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}