@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.ContentPaymentConditions{
    background-color: $softPurple-background;
    padding: 32px;
    border-radius: 14px;
}

.Documents {
    width: 100%;
    .input-file-block{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.DocumentsList {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.DocumentPolicy {
    width: 100%;
}

.DocumentPolicy.create {
    width: 100%;
    margin-top: 24px;
    .input-file-block{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.container-upload{
    align-items:center;
    flex-direction: column;
    border-color: rgba(157, 157, 161, 0.6);
    border-radius: 10px;  
}

.container-input-file-block{
    &.view{
        .list-file{
            padding: 5px;
            background: rgba(157, 157, 161, 0.08);
            border-radius: 10px;
            border: 1px solid rgba(157, 157, 161, 0.6);
            .container-upload{
                &.border-dashed {
                    border-color: rgba(157, 157, 161, 0.6);
                    padding: 20px;
                }
            }
        }
    }
}

.PaymentConditions {
    display: flex;
}

.PaymentConditionsPolicy {
    display: flex;
    width: 28%;
    margin-left: 30px;
}

.OptionsButton {
    display: column;
    flex-wrap: wrap;
    justify-content: flex-start; 
    align-items: center; 
    width: 100%;
    
    hr {
        border: 1px solid #9747FF38;
        width: 100% !important;
        margin-left: 0 !important;
    }

    &__checkboxs {
        display: flex;
        align-items: flex-end;
        margin: 11px 0 ;
        p {
            margin: 0;
        }
        pds-input {
            --margin-input: 4px 0 0 0;
        }
    }

    &__other {
        display: flex;
        align-items: flex-end;
    }
}

.displayConditions {
    margin: auto;
    display: flex ;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .selectedDays {
        color: $persian-indigo;
        font-size: 21px;
        font-weight: 600;
    }
}

b {
    color: $primary-purple;
    font-size: 21px;
}

.filesContainer {
    display: flex;
    width: 100%;
    align-items: center;
}

.ImageFacture {
    width: 100%;
}

.disabled {
    display: none;
}

.options-button__otro-dia {
    width: 100%;
}

@include mobile {
    .DocumentPolicy.create {
        width: 100%;
    }

    .OptionsButton {
        padding-top: 5%;
    }
}

@include tablet {
    .ContentPaymentConditions {
        padding: 32px;
    }
    .Documents {
        width: 88%;
    }
    .PaymentConditions {
        width: 90%;
        margin-top: 3%;
    }
    .PaymentConditionsPolicy {
        width: 90%;
        margin-top: 3%;
        padding-left: 20px;
    }
    .ImageTelecommuting, .ImageFacture{
        display: none;
    }
    .OptionsButton{
        flex-direction: column;
        width: 100%;
    }
    .custom-checkbox {
        margin: 0;
    }
    .Documents{
        margin-top: 20px;
    }
}