@import "../../../styles/variables.scss";

.approve_fields {
    position: relative;
    z-index: 1;
    color: #330072;
    background: $softPurple-background;
    box-shadow: 0px 0px 60px 0px #FFFFFF40 inset;
    box-shadow: 0px 2px 8px 0px #BDBDFF4D;
    border-radius: 12px;

    height: auto;
    width: 100%;
    padding: 40px 32px 40px 32px;

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        margin-top: 40px;
    }

    .grid-layout {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: start;
        gap: 24px;
    }

    h3 {
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        color: $strong-purple;
    }

    pds-input, pds-dropdown {
        width: 100%;
        color: #330072;
        &.input-value {
            --color-placeholder: #330072;
        }
    }
}

@media (max-width: 768px) {
    .approve_fields {
        display: flex;
        flex-direction: column;

        .grid-layout {
            display: flex;
            flex-direction: column;
            align-content: center;
            gap: 0px;
        }

        .select-economy {
            width: 100%;

            .input-text {
                width: 100%;
                margin-bottom: 10%;
            }

            .input__1,
            .input__2 {
                width: 90%;
                margin-bottom: 10%;
            }
        }

        .rte-group {
            width: 100%;

            .input-text {
                width: 90%;
                margin-bottom: 10%;
            }
        }

        .checkbox__group {
            width: 100%;

            .checkbox {
                margin-bottom: 5% !important;
            }
        }
    }
}