@import '../../../styles/variables.scss';

.horizontal-radio-buttons{
    display: flex;
}


@media screen and (max-width:700px) {
    .horizontal-radio-buttons {
        flex-direction: column;
    }
}
